// table列表项(编辑，删除等)
.operation {
  button {
    margin-right: 10px;
  }
}

// 搜索过滤条件样式
.filter-box {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

/* clearfix */
.clearfix:before,
.clearfix:after {
  content: "\20";
  display: block;
  height: 0;
  overflow: hidden;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  zoom: 1;
}

// 设置浏览器input默认颜色为透明
// input:-internal-autofill-previewed,
// input:-internal-autofill-selected {
//   -webkit-text-fill-color: rgba(255, 255, 255, 0.65) !important;
//   transition: background-color 5000s ease-in-out 0s !important;
// }
