.ajax-loading {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.component-loading {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  .sk-spinner-pulse {
    width: 4em;
    height: 4em;
    margin: auto;
    background-color: #337ab7;
    border-radius: 100%;
    -webkit-animation: sk-spinner-pulse 1s infinite ease-in-out;
    animation: sk-spinner-pulse 1s infinite ease-in-out;
  }

  @-webkit-keyframes sk-spinner-pulse {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 0;
    }
  }

  @keyframes sk-spinner-pulse {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 0;
    }
  }
}
